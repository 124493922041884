import { combineReducers } from "redux"
import activitiesPage from "./activities"
import importPage from "./import"
import projectsPage from "./projects"
import orderPositionsPage from "./orderPositions"
import reportsPage from "./reports"
import handlePlanningObjectsSharedActions from "./handlePlanningObjectsSharedActions"
import projectRevoke from "./projectRevoke"

export default combineReducers({
  activities: activitiesPage,
  import: importPage,
  projects: projectsPage,
  operations: (state, action) => handlePlanningObjectsSharedActions(action, state, "operations"),
  orderPositions: orderPositionsPage,
  productionOrders: (state, action) => handlePlanningObjectsSharedActions(action, state, "productionOrders"),
  reports: reportsPage,
  projectRevoke,
})
