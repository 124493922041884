import reduceReducers from "reduce-reducers"
import { get } from "lodash"
import ActionTypes from "../../constants/ActionTypes"
import { createAPIRequestReducer, genericRequestState, initialState } from "../api"

const INITIAL_STATE = {
  ...initialState,
  check: {
    ...genericRequestState,
    errorCode: null,
    detailsObject: null,
    activityDetails: null,
  },
  revokeStatus: {
    ...genericRequestState,
  },
}

const createActivityReducer = createAPIRequestReducer(ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_CREATE)

function updateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.PAGES_ACTIVITIES_RESET:
      return {
        ...state,
        check: INITIAL_STATE.check,
        revokeStatus: INITIAL_STATE.revokeStatus,
      }
    default:
      return state
  }
}

function checkActivityReducer(state = INITIAL_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.PLANNING_OBJECTS_DELETE_REQUEST: {
      const { type } = action.payload
      return {
        ...state,
        isPending: type === "activities",
      }
    }
    case ActionTypes.PLANNING_OBJECTS_DELETE_SUCCESS: {
      return {
        ...state,
        isPending: false,
      }
    }
    case ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_CHECK_REQUEST:
      return {
        ...state,
        check: {
          ...state.check,
          errorCode: null,
          isPending: true,
        },
      }
    case ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_CREATE_SUCCESS: {
      if (get(payload, "data.errorCode")) {
        return {
          ...state,
          check: {
            ...state.check,
            errorCode: get(payload, "data.errorCode"),
            detailsObject: get(payload, "data.detailsObject"),
            isPending: false,
          },
        }
      }
      return state
    }
    case ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_CREATE_FAILURE:
      return {
        ...state,
        check: {
          ...state.check,
          errorCode: get(payload, "errorCode"),
          detailsObject: get(payload, "data.detailsObject"),
          isPending: false,
        },
      }
    case ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_CHECK_FAILURE:
      return {
        ...state,
        check: {
          ...state.check,
          errorCode: get(payload, "response.errorCode"),
          detailsObject: get(payload, "response.detailsObject"),
          isPending: false,
        },
      }
    case ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_CHECK_SUCCESS:
      return {
        ...state,
        check: {
          errorCode: null,
          detailsObject: null,
          isPending: false,
          isFinished: true,
          activityDetails: { ...action.payload[0] },
        },
      }

    default:
      return state
  }
}

export default reduceReducers(updateReducer, createActivityReducer, checkActivityReducer)
