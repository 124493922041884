import { takeLatest, put as putEffect } from "redux-saga/effects"
import { createAction } from "../../../utils/redux"
import ActionTypes from "../../../constants/ActionTypes"
import { APIGenericSequence, APIRequestSequence } from "../../api"
import { API_SERVER_PLANNING_PROCESS } from "../../../constants/api"
import activitySchema from "../../../schemas/planningObjects/activitiesList"
import activityDetailsSchema from "../../../schemas/planningObjects/activityDetails"
import { post, put } from "../../../utils/http"

export default [
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_LOAD,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/activities`,
    schema: activityDetailsSchema,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.activityId}`,
    prepareResponse: response => [response],
  }),

  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_UPDATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/activities`,
    schema: activitySchema,
    method: put,
    prepareRequest: payload => payload.data,
    prepareResponse: response => [{ ...response, activity: { ownedBy: null, ...response.activity } }],
    prepareUrl: (endPoint, { data }) => `${endPoint}/${data.activity.activityId}`,
  }),
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_CREATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/activities/productionOrders`,
    schema: activitySchema,
    method: post,
    prepareRequest: ({ data }) => data,
    prepareResponse: activity => [activity],
  }),
  APIGenericSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_CHECK,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/activities/productionOrders/check`,
    method: post,
    prepareRequest: ({ data }) => data,
    prepareResponse: activity => [activity],
  }),

  takeLatest(ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_CREATE_SUCCESS, ({ payload: { result } }) =>
    result ? watchActivityCreateSuccess(result[0]) : _ => _
  ),
]

function* watchActivityCreateSuccess(activityId) {
  yield putEffect(createAction(ActionTypes.UI_PLANNING_OBJECTS_DETAILS_ACTIVITY_NAVIGATE, { activityId }))
  yield putEffect(createAction(ActionTypes.PAGES_ACTIVITIES_RESET))
}
