import { schema } from "normalizr"
import activity from "../activities/item"
import project from "../projects/item"
import productionOrders from "../productionOrders"

export default new schema.Entity(
  "activityDetails",
  {
    activity,
    productionOrders,
    project,
  },
  {
    idAttribute: value => value.activity.activityId,
  }
)
