import { isNil, uniq } from "lodash"
import reduceReducers from "reduce-reducers"
import { Action } from "redux"

import { initialState } from "../api"
import { createPlanningObjectsReducer } from "../planningObjects"
import ActionTypes from "../../constants/ActionTypes"

interface NavigateActionPayload {
  [key: string]: Array<object>
}

interface NavigateAction extends Action {
  payload: NavigateActionPayload
}

interface State {
  isPending: boolean
  isLoaded: boolean
  error: object
  entities: object[]
}

const planningObjectReducer = createPlanningObjectsReducer("activities")

function updateReducer(state = initialState as State, action: NavigateAction) {
  if (action.type === ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_CREATE_SUCCESS && !isNil(action.payload.result)) {
    return {
      ...state,
      entities: uniq([...action.payload.result, ...state.entities]),
    }
  }
  return state
}

export default reduceReducers(updateReducer, planningObjectReducer)
