import reduceReducers from "reduce-reducers"
import { get } from "lodash"

import ActionTypes from "../../constants/ActionTypes"
import { createPlanningObjectsReducer } from "../planningObjects"

function updateReducer(state = { errorCode: null }, action) {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_UPDATE_FAILURE:
      return {
        ...state,
        error: get(payload, "response.errorCode"),
        errorCode: get(payload, "response.errorCode"),
        detailsObject: get(payload, "response.detailsObject"),
      }

    case ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_LOAD_REQUEST:
    case ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        errorCode: null,
      }

    default:
      return state
  }
}

export default reduceReducers(createPlanningObjectsReducer("productionOrders"), updateReducer)
