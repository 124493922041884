import React, { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useLazyQuery, gql } from "@apollo/client"
import { get, isEmpty, noop, toLower } from "lodash"
import { IntlProvider } from "react-intl"
import { useLocation } from "react-router"
import PropTypes from "prop-types"

import moment from "moment"
import "moment/locale/de"
import "moment/locale/en-gb"

import defaultMessages from "../../i18n/json/en.json"
import formats from "../../i18n/formats"
import { fetchAccessibleLicenceModules } from "../../actions/accessibleLicenceModules"
import { fetchAmountUnits } from "../../actions/planningObjects/amountUnits"
import { fetchResources } from "../../actions/basedata/resources"
import { fetchCustomers } from "../../actions/basedata/customers"
import { createAction } from "../../utils/redux"
import { get as getHttp } from "../../utils/http"

import ActionTypes from "../../constants/ActionTypes"
import { API_SERVER_USER } from "../../constants/api"
import { fetchLanguages } from "../../actions/pages/languages"
import { useInit } from "../App/useInit"
import useFetchSettings from "../../hooks/useFetchSettings"
import LocalizationProviderDatePicker from "./LocalizationProviderDatePicker"
import { isScreenPage } from "../../utils/checkLocation"
import { getUserToken } from "../../selectors/token"

const ADDITIONAL_PROPS = gql`
  query propertyDefinitions {
    properties {
      items {
        id
        datatype
        propertyName
        propertyType
      }
    }
  }
`

const mapDispatchToProps = {
  fetchAccessibleLicenceModules,
  fetchAmountUnits,
  fetchCustomers,
  fetchLanguages,
  fetchResources,
}

const Empty = () => <span />

const userLanguageEndpoint = "/users/translation"
const clientLanguageEndpoint = "/clients/translation"
const fallbackLanguage = "de"

const useProperties = () => {
  const dispatch = useDispatch()
  const [getProperties] = useLazyQuery(ADDITIONAL_PROPS, {
    onCompleted: data => {
      const props = get(data, "properties.items", [])
      dispatch(createAction(ActionTypes.ADDITIONAL_PROPERTIES_SUCCESS, props))
    },
  })
  return getProperties
}

const useLanguageFromStore = () => {
  const screenLanguage = useSelector(state => get(state, "user.screen.screenLanguage"))
  const userLanguage = useSelector(state => get(state, "user.authentication.user.language"))
  return screenLanguage || userLanguage ? toLower(screenLanguage || userLanguage) : null
}

const loadLocalTranslations = (lang, setData) => {
  import(/* webpackChunkName: "translations[index]" */ `../../i18n/json/${lang}.json`)
    .then(module => {
      setData(module.default, lang)
    })
    .catch(() =>
      import(/* webpackChunkName: "translations[index]" */ `../../i18n/json/${fallbackLanguage}.json`).then(module => {
        setData(module.default, fallbackLanguage)
      })
    )
}

const InitializeApp = ({
  children,
  textComponent,
  fetchAccessibleLicenceModules: fetchAccessibleLicenceModulesAction,
  fetchAmountUnits: fetchAmountUnitsAction,
  fetchCustomers: fetchCustomersAction,
  fetchLanguages: fetchLanguagesAction,
  fetchResources: fetchResourcesAction,
}) => {
  const fetchSettings = useFetchSettings()
  const location = useLocation()
  const isTVScreen = isScreenPage(location)
  const getProperties = useProperties()
  const token = useSelector(state => getUserToken(state))
  const authenticated = useSelector(state => get(state, "user.authentication.authenticated"))
  const pinAuthenticated = useSelector(state => get(state, "user.terminalAuthentication.pinAuthenticated"))
  const isLoadedStandard = useSelector(state => get(state, "user.authentication.isLoaded"))
  const isLoadedTerminal = useSelector(state => get(state, "user.terminalAuthentication.isLoaded"))
  const isLoaded = isLoadedStandard || isLoadedTerminal
  // Language from redux store
  const languageFromStore = useLanguageFromStore()

  const [language, setLanguage] = useState()
  const [localeMessages, setLocaleMessages] = useState({})
  const [locale, setLocale] = useState(null)

  useInit(location, authenticated)

  useEffect(() => {
    if (authenticated) {
      // fetch
      fetchLanguagesAction()
      fetchAccessibleLicenceModulesAction()
      fetchAmountUnitsAction()
      fetchCustomersAction()
      fetchResourcesAction()
      fetchSettings()
      getProperties()
    }
  }, [authenticated])
  useEffect(() => {
    if (pinAuthenticated) {
      fetchSettings()
      fetchAccessibleLicenceModulesAction()
    }
  }, [pinAuthenticated])

  const setData = (translations, lang, localeFromBackend = null) => {
    const mergedTranslations = { ...defaultMessages, ...translations }
    setLanguage(lang)
    setLocaleMessages(mergedTranslations)
    setLocale(localeFromBackend || lang)
  }

  useEffect(() => {
    const endpoint = authenticated || pinAuthenticated ? userLanguageEndpoint : clientLanguageEndpoint
    if (!authenticated && !pinAuthenticated && !isTVScreen && language) {
      // on normal login we set language to null
      return
    }
    if (isTVScreen && pinAuthenticated) {
      // TVScreen has always de or en
      loadLocalTranslations(languageFromStore, setData)
      return
    }
    if (pinAuthenticated || authenticated) {
      // preload of default lang
      loadLocalTranslations(languageFromStore, setData)
    }
    getHttp({
      url: `${API_SERVER_USER}${endpoint}`,
      token: authenticated || pinAuthenticated ? token : null,
    })
      .then(fetchData => {
        const receivedTranslations = get(fetchData, "translations")
        const receivedLanguage = get(fetchData, "language")
        const receivedLocale = get(fetchData, "locale")
        if (!receivedTranslations) {
          const lang = languageFromStore || receivedLanguage
          loadLocalTranslations(lang, setData)
          return
        }
        if (receivedLocale) {
          // eslint-disable-next-line no-unused-expressions
          import(`moment/locale/${get(fetchData, "locale")}`)
        }
        setData(receivedTranslations, receivedLanguage, receivedLocale)
      })
      .catch(() => {
        loadLocalTranslations(languageFromStore, setData)
      })
  }, [authenticated, pinAuthenticated, languageFromStore])

  useEffect(() => {
    if (isLoaded) {
      let languageLocale = language === "en" ? "en-gb" : locale
      languageLocale = languageLocale || "de"
      moment.locale(languageLocale)
    }
  }, [isLoaded, locale])

  return (
    <IntlProvider
      formats={formats}
      key={language || "nix"}
      locale={language}
      messages={localeMessages}
      onError={isEmpty(localeMessages) ? noop : undefined}
      textComponent={isEmpty(localeMessages) ? Empty : textComponent}
    >
      <LocalizationProviderDatePicker> {children}</LocalizationProviderDatePicker>
    </IntlProvider>
  )
}

InitializeApp.defaultProps = {
  textComponent: undefined,
}

InitializeApp.propTypes = {
  textComponent: PropTypes.symbol,
  fetchAccessibleLicenceModules: PropTypes.func.isRequired,
  fetchAmountUnits: PropTypes.func.isRequired,
  fetchCustomers: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  fetchResources: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(InitializeApp)
