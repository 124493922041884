import ActionTypes from "../../constants/ActionTypes"
import { createGenericRequestReducer, genericRequestState } from "../api"
import accessProxy from "../../utils/accessProxy"

export const Steps = accessProxy({
  CHOOSE: 0,
  VALIDATE: 1,
  IMPORT: 2,
})

const specificInitialState = {
  fileUpload: { ...genericRequestState },
  importError: null,
  importErrorFile: null,
  importProcess: { ...genericRequestState },
  selectedImport: 0,
  step: Steps.CHOOSE,
  uploadedFile: null,
  validation: { ...genericRequestState },
  validationError: null,
  validationErrorFile: null,
}

const fileReducer = createGenericRequestReducer(ActionTypes.IMPORTS_UPLOAD)
const importsReducer = createGenericRequestReducer(ActionTypes.IMPORTS_PROCESS)
const validationReducer = createGenericRequestReducer(ActionTypes.IMPORTS_VALIDATE)

export default (state = specificInitialState, action) => {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.IMPORTS_CLEAR_FILE: {
      return {
        ...state,
        uploadedFile: null,
        uploadedFileName: null,
      }
    }
    case ActionTypes.IMPORTS_TYPE_SELECT: {
      return {
        ...state,
        selectedImport: action.payload,
      }
    }
    case ActionTypes.IMPORTS_UPLOAD_SUCCESS: {
      return {
        ...state,
        fileUpload: fileReducer(state.fileUpload, action),
        uploadedFile: payload.fileName,
        uploadedFileName: payload.originFileName,
      }
    }
    case ActionTypes.IMPORTS_UPLOAD_FAILURE: {
      const { message } = payload
      return {
        ...state,
        uploadError: message,
        fileUpload: fileReducer(state.fileUpload, action),
      }
    }
    case ActionTypes.IMPORTS_VALIDATE_REQUEST: {
      return {
        ...state,
        step: Steps.VALIDATE,
        validation: validationReducer(state.validation, action),
        validationError: null,
        validationErrorFile: null,
      }
    }
    case ActionTypes.IMPORTS_VALIDATE_SUCCESS: {
      const { errorCode, errorFileName, status } = payload
      const failure = status === "Failed"

      return {
        ...state,
        validation: validationReducer(state.validation, action),
        validationError: failure ? errorCode : null,
        validationErrorFile: failure ? errorFileName : null,
      }
    }
    case ActionTypes.IMPORTS_RESET: {
      return specificInitialState
    }
    case ActionTypes.IMPORTS_PROCESS_REQUEST: {
      return {
        ...state,
        step: Steps.IMPORT,
        importProcess: importsReducer(state.importProcess, action),
      }
    }
    case ActionTypes.IMPORTS_PROCESS_FAILURE: {
      return {
        ...state,
        importError: "FAILED",
        importProcess: importsReducer(state.importProcess, action),
      }
    }
    case ActionTypes.IMPORTS_PROCESS_SUCCESS: {
      const { errorCode, errorFileName, errorMessage } = payload

      return {
        ...state,
        importError: errorCode,
        importErrorFile: errorFileName,
        importProcess: importsReducer(state.importProcess, action),
        importErrorMessage: errorMessage,
      }
    }
    case ActionTypes.IMPORTS_BACK_TO_IMPORT: {
      return {
        ...state,
        importError: null,
        importProcess: { ...genericRequestState },
        step: Steps.VALIDATE,
      }
    }
    default:
      return {
        ...state,
        fileUpload: fileReducer(state.fileUpload, action),
        validation: validationReducer(state.validation, action),
      }
  }
}
